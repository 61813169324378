<template>
    <div class="link" ref="linkArea">
        <div class="link-head">
            <span class="link-head-title">Your Link for this Product</span>
            <span class="link-head-extra" v-if="sourceName"><strong>Source: </strong>{{ sourceName }}</span>
        </div>
        <div class="link-body" v-if="trackLinkId">
            <div class="link-scroll-area">
                <div class="link-textarea">
                    <span class="link-copy-btn" @click="onCopyCode">
                        <svg class="icon" aria-hidden="true" style="font-size: 22px">
                            <use xlink:href="#icon-copy2"></use>
                        </svg>
                    </span>
                    {{ trackLink }}
                </div>
            </div>
            <div class="link-opt">
                <artmisads-select-button @click="() => onShowLinkModal()">
                    <svg class="icon" aria-hidden="true" style="font-size: 20px; margin-right: 8px;">
                        <use xlink:href="#icon-jiahao"></use>
                    </svg>
                    Create New Link
                </artmisads-select-button>
                <a-space>
                    <artmisads-select-button type="text" @click="showChartModal">
                        <svg class="icon" aria-hidden="true" style="font-size: 20px; margin-right: 8px;">
                            <use xlink:href="#icon-chart"></use>
                        </svg>
                        Performance
                    </artmisads-select-button>
                    <artmisads-select-button @click="() => onShowLinkModal(trackLinkId)">
                        <svg class="icon" aria-hidden="true" style="font-size: 20px; margin-right: 8px;">
                            <use xlink:href="#icon-bianji"></use>
                        </svg>
                        Edit Link
                    </artmisads-select-button>
                </a-space>
            </div>
        </div>
        <div class="link-body" v-else>
            <div class="link-new">
                <p>You have no saved links for this Product</p>
                <artmisads-select-button size="large" @click="() => onShowLinkModal()">
                    <svg class="icon" aria-hidden="true" style="font-size: 20px; margin-right: 8px;">
                        <use xlink:href="#icon-jiahao-cu"></use>
                    </svg>
                    Create New Link
                </artmisads-select-button>
            </div>
        </div>
        <!-- 两种场景 -->
        <!-- 从未生成过Track Link的需要传productId -->
        <!-- 生成过Track Link的则需要传trackLinkId -->
        <LinkToolModal :productId="productId" :trackLinkId="openTrackLinkId" :countryCode="countryCode"
            v-model:open="open" @callback="onSaveCallback" />
        <lineChartModal
            v-if="showlineChartModal"
            :linkMd5="linkMd5 || updateLinkMd5"
            title="Link Performance"
            :itemInfo="itemInfo"
            :onCancleModal="onCancleChartModal"
            pageType="publisherLink"
            :marketplace="marketplace"
            defaultMetricName="detailPageViews"
            :noNeedList="['clicks', 'acosAmount', 'salesAmount', 'bonusAmount', 'commissionAndFeeAmount']"
        />
    </div>
</template>

<script setup>
import { ref, inject, watchEffect } from 'vue'
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import lineChartModal from '~/components/reports/lineChartModal.vue';

import LinkToolModal from './linkToolModal.vue';

const itemInfo = ref(null)
const open = ref(false);
const showlineChartModal = ref(false);
const productId = inject('productId')

const {
    id, value, countryCode, source, linkMd5, marketplace, productTitle, productAsin, productImageUrl
} = defineProps({
    id: Number || undefined, value: String, countryCode: String, source: String, linkMd5: String, marketplace: String,
    productTitle: String, productAsin: String, productImageUrl: String
})

const sourceName = ref(source)
const updateLinkMd5 = ref('')

const trackLinkId = ref(id)
const trackLink = ref(value)

const openTrackLinkId = ref();

const showChartModal = () => {
    showlineChartModal.value = true;
    itemInfo.value =  { id, value, countryCode, source, linkMd5, productTitle, productAsin, productImageUrl };
}

const onCancleChartModal = () => {
    showlineChartModal.value = false;
    itemInfo.value = undefined;
} 

const onShowLinkModal = id => {
    openTrackLinkId.value = id;
    open.value = true;
}

const onCopyCode = async () => {
    if (trackLink.value) {
        try {
            await navigator.clipboard.writeText(trackLink.value)
            message.success('Copied Successfully', 1);
        } catch (error) {
            message.success('Failed!', 1);
        }
    }
}

const onSaveCallback = updateData => {
    updateLinkMd5.value = updateData.linkMd5;
    sourceName.value = updateData.sourceName
    trackLinkId.value = updateData.trackLinkId;
    trackLink.value = updateData.shortLinkSelect ? updateData.shortLink : updateData.trackingLink;
    open.value = false;
}

</script>

<style scoped lang="less">
.link {
    margin-top: 40px;
    .link-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 28px;
        line-height: 28px;
        margin: 16px 0 14px;

        .link-head-title {
            font-weight: 600;
            font-size: 20px;
            color: var(--dark-1);
            letter-spacing: 0.5px;
        }

        .link-head-extra {
            letter-spacing: 0.4px;
            font-size: 14px;
            font-weight: 500;
            color: var(--dark-2);

            strong {
                font-weight: 400;
            }
        }
    }

    .link-body {
        position: relative;
        padding-bottom: 16px;

        .link-new {
            background-color: var(--bg-gray-2);
            border-radius: 8px;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            height: 160px;
            align-items: center;
            gap: 16px;
            width: 100%;

            p {
                opacity: 0.7;
                font-weight: 500;
                font-size: 16px;
                color: var(--dark-1);
                letter-spacing: 0.4px;
                text-align: center;
                line-height: 22px;
            }

            button {
                display: inline-flex;
                align-items: center;
                font-weight: 500;
            }
        }

        .link-scroll-area {
            min-height: 80px;
            background-color: #EEEDF2;
            box-shadow: 0 1px 0 0 var(--box-shadow-2);
            border-radius: 8px;
            border: 1px solid #CCC7D9;
            box-sizing: border-box;
            padding: 8px 72px 7px 12px;
            line-height: 18px;
            word-break: break-word;
            color: var(--dark-2);
            .link-textarea {
                cursor: not-allowed;
                user-select: none;
                min-height: 58px;
                max-height: 84px;
                overflow-y: auto;
            }

            .link-copy-btn {
                cursor: pointer;
                display: block;
                position: absolute;
                right: 8px;
                top: 8px;
                display: flex;
                width: 56px;
                height: 64px;
                justify-content: center;
                align-items: center;
                background-color: var(--color-white);
                border-radius: 8px;

                svg {
                    fill: var(--dark-3);
                }
                &:hover > svg {
                    fill: var(--dark-2);
                }
                &:active > svg {
                    fill: var(--dark-1);
                }
            }
        }

        .link-opt {
            margin-top: 16px;
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            :deep(.ant-btn) {
                &.artmisads-select-button {
                    display: flex;
                    padding: 0 12px;
                    line-height: 32px;
                    align-items: center;
                }
            }
        }
    }
}

.source-edit {
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    background-color: var(--bg-gray-1);
    width: 260px;
    height: 32px;
    justify-content: center;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed var(--border-1);
}
</style>
<template>
    <artmisads-modal class="commission-history-modal" :width="1200" v-model:open="open" :footer="null"
        :maskClosable="false">
        <template #title>
            <span class="title">Commission History</span>
        </template>
        <div ref="chartRef" class="commission-chart"></div>
        <div class="commission-history">
            <artmisads-table :columns="columnsHistory" row-key="id" :pagination="false" rowClassName="table-item"
                :data-source="commissionHistoryList" />
            <p v-if="commissionHistoryList.length">
                {{COUNTRY_COMMISSION_TXT[countryCode]}}
            </p>
        </div>
    </artmisads-modal>
</template>

<script setup>
import { ref, computed, watch, watchEffect, onMounted } from 'vue';

import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useUserInfoStore } from '~/storeData/index';
import { COUNTRY_COMMISSION_TXT, COUNTRY_TIME_TZ } from '~/contants/common';

import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';

import moment from 'moment-timezone';

import { publisherProductApi } from '~/server/index'

const { productId, countryCode } = defineProps({ productId: String, countryCode: String })

const store = useUserInfoStore();
const chartRef = ref()
const open = defineModel('open')
const commissionHistoryChart = ref(null)
const timezone = COUNTRY_TIME_TZ[countryCode || 'US']

const commissionHistoryList = ref([])

const columnsHistory = [
    {
        title: 'Date',
        dataIndex: 'startDate',
        customRender: ({ text, record }) => moment(text).tz(timezone).format('YYYY/MM/DD')
    },
    {
        title: 'Commission',
        dataIndex: 'commission',
        customRender: ({ text, record }) => `${Number(text).toFixed(1)}%`
    },
    {
        title: 'Difference',
        dataIndex: 'difference',
        customRender: ({ text, record }) => `${text ? `${Number(text).toFixed(1)}%` : '-'}`
    },
];

onMounted(() => {
    echarts.use([LineChart, TitleComponent, TooltipComponent, GridComponent, LegendComponent, CanvasRenderer])
})

watch([open, chartRef, commissionHistoryChart], async ([open, chartRef, commissionHistoryChart]) => {
    if (open && chartRef) {
        if (!commissionHistoryChart) {
            commissionHistoryChart = echarts.init(chartRef)
        }
        let { success, data } = await publisherProductApi.getCommissionHistoryByProductId(productId)

        if (success) {
            commissionHistoryList.value = data;

            if (data.length) {
                let chartOptions = {
                    grid: {
                        width: 1080,
                        left: '1%',
                        right: '1%',
                        bottom: '5%',
                        top: '5%',
                        containLabel: true,
                    },
                    tooltip: {
                        valueFormatter: value => `${value}%`,
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: (() => {
                            let optData = data.map((item, index) => moment(item.startDate).format('MMM DD')).reverse()
                            optData.push('Current')
                            return optData
                        })(),
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(15, 6, 41, .5)'
                            }
                        },
                        axisLabel: {
                            fontSize: 12
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%'
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(15, 6, 41, .7)'
                            }
                        }
                    },
                    series: [
                        {
                            data: (() => {
                                let optData = data.map(item => item.commission).reverse()
                                optData.push(data[0].commission)
                                return optData
                            })(),
                            step: 'end',
                            type: 'line',
                            color: '#835CF2',
                            lineStyle: {
                                width: 2
                            },
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: 'rgb(131, 92, 242, .3)' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: 'rgb(131, 92, 242, 0)' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                            }
                        }
                    ]
                }
                commissionHistoryChart.setOption(chartOptions)
            }
        }
    }
})

</script>

<style lang="less">
.commission-chart {
    height: 300px;
    margin: 0 36px
}

.commission-history-modal {
    .ant-modal-body {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}

.commission-history {
    margin-top: 30px;

    >p {
        margin: -7px 0 15px;
        opacity: 0.5;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 20px;
        color: var(--dark-3);
    }
}
</style>
<template>
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-shangpin"></use>
      </svg>
    </breadcrumbs>
    <main class="main_container">
        <div class="product">
            <pulic-report-notice type="urgent" v-if="info.status === 'inactive'">
                <template #title>Product it not active</template>
            </pulic-report-notice>
            <Information class="box" v-bind="info">
                <template #deals>
                    <a-skeleton :loading="spinning">
                        <div style="margin-top: 28px;margin-bottom: 28px;" v-if="info.productDeals?.length">
                            <div class="deal-title ">Deals</div>
                            <ProductDeals :dataSource="info.productDeals" :countryCode="info.countryCode" />
                            <div class="subtitle">Note: The deals are provided by the seller themselves and haven't undergone verification. It is advisable
                                to confirm the details of the deal with the seller prior to any promotion.</div>
                        </div>
                    </a-skeleton>
                </template>
                <template #link v-if="store.userInfo.reviewStatus != 'Pending' && info.status != 'inactive'">
                    <a-skeleton :loading="spinning">
                        <LinkArea v-bind="trackLink" />
                    </a-skeleton>
                </template>
            </Information>
            <variations v-if="info.variationTotal" :id="productId" userType="publisher" :showStatus="false" />
        </div>
    </main>
</template>

<script setup>
import { provide } from 'vue'
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import breadcrumbs from '../../../seller/breadcrumbs.vue'
import { useUserInfoStore } from '../../../../storeData/index';
import { CURRENCY_MAPPER } from '~/contants/common';

import LinkArea from './linkArea.vue';
import Information from './information.vue';
import Variations from '~/components/variations.vue';
import ProductDeals from '~/components/ProductDeals.vue';
import PulicReportNotice from "~/components/PulicReportNotice.vue";

import { publisherProductApi } from '~/server/index'

const route = useRoute();
const store = useUserInfoStore();
const { id: productId } = route.params

provide('productId', productId)

const breadcrumbsData = {
  color: '#4682FA',
  title: 'Product Detail',
  links: [
    {
      name: 'Home',
      path: '/publisher/products',
    },
    {
      name: 'Products',
      path: '/publisher/products',
    },
    {
      name: 'Product Detail',
      path: `/publisher/products/${productId}`,
    },
  ],
}

const trackLink = ref({
    id: 0,
    value: '',
    countryCode: '',
    source: '',
    linkMd5: '',
    marketplace: '',
    productTitle: '',
    productAsin: '',
    productImageUrl: ''
})

const info = ref({})

const spinning = ref(true);

const getProductDetail = async () => {
    if (!productId) return
    spinning.value = true;
    const res = await publisherProductApi.getProductDetail(productId).catch(e => message.error('System error, please try again later.'))
    const { success, data } = res;
    if (success) {
        trackLink.value.id = data.latestTrackingLink?.id || 0
        trackLink.value.value = data.latestTrackingLink?.shortLinkSelect ? data.latestTrackingLink?.shortLink : data.latestTrackingLink?.trackingLink
        trackLink.value.countryCode = data.countryCode
        trackLink.value.productTitle = data.title
        trackLink.value.productAsin = data.asin
        trackLink.value.productImageUrl = data.imageUrl
        trackLink.value.marketplace = CURRENCY_MAPPER[data.countryCode].host
        trackLink.value.source = data.latestTrackingLink?.source || ''
        trackLink.value.linkMd5 = data.latestTrackingLink?.linkMd5 || ''

        info.value = data;
    }
    spinning.value = false;
}

onMounted(() => {
    getProductDetail();
})

</script>

<style scoped lang="less">
.main_container {
    // display: flex;
    // flex-wrap: nowrap;
    // justify-content: space-between;
    // align-items: flex-start;
    // gap: 15px;

    .skeleton-loading {
        :deep {
            .ant-skeleton-image {
                width: 300px;
                height: 300px;
                line-height: 300px;
            }
        }
    }
}

.product {
    flex: 1;
    gap: 16px;
    display: flex;
    flex-direction: column;
    :deep(.report-notice) {
        margin-bottom: 0;
    }
    .box {
        padding: 16px;
        border-radius: 8px;
        background: var(--color-white);
        box-shadow: var(--section-box-shadow-6);
    }
}
.deal-title {
    font-weight: 600;
    font-size: 20px;
    color: var(--dark-1);
    letter-spacing: 0.5px;
    margin-bottom: 14px;
}
.subtitle {
    margin-top: -8px;
    color: var(--dark-3);
}
</style>